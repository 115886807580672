import React, {useEffect, useState} from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Skeleton,
  Select,
  NumberInput,
  NumberInputField,
  useToast,
  Image,
} from '@chakra-ui/react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Import styles
import {getEpisode, updateEpisode} from 'shared/requests/app-requests'
import {Episode} from 'shared/models/NestModels'
import {useNavigate} from 'react-router-dom'

interface EpisodeEditProps {
  id: string
}

const EpisodeEdit = ({id}: EpisodeEditProps) => {
  const [episode, setEpisode] = useState<Episode | null>(null)
  const [loading, setLoading] = useState(true)
  const [name, setName] = useState('')
  const [season, setSeason] = useState('')
  const [number, setNumber] = useState<number>(0)
  const [guest, setGuest] = useState('')
  const [content, setContent] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const toast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    getEpisode(id).then((response) => {
      setEpisode(response.data)
      setName(response.data.name || '')
      setSeason(response.data.season || '')
      setNumber(response.data.number || 0)
      setGuest(response.data.guest || '')
      setContent(response.data.content || '')
      setLoading(false)
    })
  }, [id])

  const handleSave = () => {
    const formData = new FormData()

    formData.append('name', name)
    formData.append('season', season)
    formData.append('number', number.toString())
    formData.append('guest', guest)
    formData.append('content', content)

    if (file) {
      formData.append('file', file)
    }

    updateEpisode(formData, id).then(() => {
      toast({
        title: 'Episodio actualizado.',
        description: 'El episodio ha sido actualizado con éxito.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      navigate(`/episode/view/${id}`)
    })
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null
    setFile(selectedFile)
  }

  if (loading) {
    return (
      <Box padding='6' boxShadow='lg' bg='white'>
        <Skeleton height='40px' />
        <Skeleton height='200px' mt='4' />
        <Skeleton height='20px' mt='4' />
        <Skeleton height='20px' mt='4' />
        <Skeleton height='20px' mt='4' />
      </Box>
    )
  }

  return (
    <Box padding='6' boxShadow='lg' bg='white'>
      <form onSubmit={(e) => e.preventDefault()}>
        <VStack spacing={4} align='start'>
          <FormControl id='headerImage'>
            <FormLabel>Imagen Principal:</FormLabel>
            {episode?.headerImgUrl && (
              <Image
                src={episode.headerImgUrl}
                alt={episode.name}
                borderRadius='md'
                objectFit='cover'
                mb={4}
              />
            )}
            <Input type='file' onChange={handleFileChange} />
          </FormControl>

          <FormControl id='name' isRequired>
            <FormLabel>Título</FormLabel>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </FormControl>

          <FormControl id='season'>
            <FormLabel>Temporada</FormLabel>
            <Select value={season} onChange={(e) => setSeason(e.target.value)}>
              <option value='Temporada 1'>Temporada 1</option>
              <option value='Temporada 2'>Temporada 2</option>
              <option value='Temporada 3'>Temporada 3</option>
              <option value='Temporada 4'>Temporada 4</option>
            </Select>
          </FormControl>

          <FormControl id='number'>
            <FormLabel>Número de Episodio</FormLabel>
            <NumberInput
              value={number}
              onChange={(valueString) => setNumber(parseInt(valueString))}
            >
              <NumberInputField />
            </NumberInput>
          </FormControl>

          <FormControl id='guest'>
            <FormLabel>Nombre del Invitado/a</FormLabel>
            <Input value={guest} onChange={(e) => setGuest(e.target.value)} />
          </FormControl>

          {/* <FormControl id='blog'>
            <FormLabel>Podcast</FormLabel>
            <Select value={blogId} onChange={(e) => setBlogId(e.target.value)}>
              {blogs.map((blog) => (
                <option key={blog.id} value={blog.id}>
                  {blog.name}
                </option>
              ))}
            </Select>
          </FormControl> */}

          <FormControl id='content'>
            <FormLabel>Detalle</FormLabel>
            <ReactQuill value={content} onChange={setContent} />
          </FormControl>

          <Button colorScheme='brand' backgroundColor='brand.300' onClick={handleSave}>
            Guardar
          </Button>
        </VStack>
      </form>
    </Box>
  )
}

export default EpisodeEdit
