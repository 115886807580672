import React, {useEffect, useState} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from 'modules/layout/MasterLayout'
import {renderLazyComponentsNoGridv2} from 'app/loadComponents'
import {MenuModel} from 'shared/components/Sidebar/_models'
import HomePage from 'app/libraries/arcovia/home/HomePage'
// import RequestConsentPage from 'app/libraries/arcovia/consent/RequestConsentPage'
import UnderConstruction from 'app/libraries/under-construction/UnderConstruction'
import AuthorsPage from 'app/libraries/arcovia/authors/AuthorsPage'
import AdvertisingPage from 'app/libraries/arcovia/advertising/AdvertisingPage'
import ArticlesPage from 'app/libraries/arcovia/articles/ArticlesPage'
import RecordPage from 'shared/components/RecordPage/RecordPage'
import {IconType} from 'shared/helper/DynamicIcon'
import EpisodesPage from 'app/libraries/arcovia/episodes/EpisodesPage'

export const navigationData = [
  {
    id: 'home',
    label: 'Inicio',
    icon: IconType.House,
    path: 'home',
    component: <HomePage />,
  },
  {
    id: 'authors',
    label: 'Autores',
    icon: IconType.Account,
    path: 'authors',
    component: <AuthorsPage />,
  },
  {
    id: 'articles',
    label: 'Artículos',
    icon: IconType.Articles,
    path: 'articles',
    component: <ArticlesPage />,
  },
  {
    id: 'epidodes',
    label: 'Episodios',
    icon: IconType.Articles,
    path: 'episodes',
    component: <EpisodesPage />,
  },
  {
    id: 'advertising',
    label: 'Publicidad',
    icon: IconType.Account,
    path: 'advertising',
    component: <AdvertisingPage />,
  },
  {
    id: 'page-config',
    label: 'Configuración del Sitio',
    icon: IconType.Gear,
    path: 'page-config',
    component: <UnderConstruction />,
  },
] as MenuModel[]

const PrivateRoutes = () => {
  const [components, setComponents] = useState<any>([])

  // useEffect(() => {
  //   const dynamicComponents = renderLazyComponentsNoGridv2(navigationData)
  //   setComponents(dynamicComponents)
  // }, [])

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* <Route path='profile' element={<MyProfilePage />} /> */}
        {/* <Route path='home' element={<HomePage />} /> */}
        {navigationData.map((item) => {
          return <Route key={item.id} path={item.path} element={item.component} />
        })}
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
        {/* <Route path='company/:friendlyUrl' element={<CompanyPage />} /> */}
        <Route path=':object/:action/:id' element={<RecordPage />} />
        <Route path='*' element={<Navigate to='/home' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
