import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import React, {useRef} from 'react'

interface DeleteModalInterface {
  handleDelete: any
  isOpen: boolean
  leastDestructiveRef: React.RefObject<HTMLButtonElement>
  onClose: () => void
  title?: string
  message?: string
}

export const DeleteModal = ({
  handleDelete,
  title = 'Eliminar Registro',
  message = '¿Estás seguro de que deseas eliminar este registro? Esta acción no puede ser revertida.',
  isOpen,
  leastDestructiveRef,
  onClose,
}: DeleteModalInterface) => {
  const cancelRef = useRef<HTMLButtonElement>(null)

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {title}
          </AlertDialogHeader>
          <AlertDialogBody>{message}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancelar
            </Button>
            <Button colorScheme='red' onClick={handleDelete} ml={3}>
              Eliminar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
