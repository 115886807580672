import React from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
} from '@chakra-ui/react'
import {AddIcon} from '@chakra-ui/icons'
import {useForm, Controller} from 'react-hook-form'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import DynamicPicklist from '../DynamicPicklist/DynamicPicklist'
import {Column, Paginated, PaginatedRequestParams} from '../DynamicTable/models'

export enum FieldType {
  Text = 'text',
  Number = 'number',
  Decimal = 'decimal',
  Picklist = 'picklist',
  File = 'file',
  Lookup = 'lookup',
  RichText = 'rich-text',
}

interface Field {
  fieldName: string
  friendlyUserName: string
  isRequired: boolean
  type: FieldType
  options?: string[]
  fetchLookupData?: (
    params: PaginatedRequestParams & Record<string, any>
  ) => Promise<Paginated<any>>
}

interface CreateModalProps {
  fields: Field[]
  onSubmit: (data: FormData) => void // Update to FormData
  objectFriendlyName: string
  lookupColumns?: Column<any>[]
}

const CreateModal = ({fields, onSubmit, objectFriendlyName, lookupColumns}: CreateModalProps) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const {handleSubmit, control, reset} = useForm()

  const handleFormSubmit = (data: {[key: string]: any}) => {
    const formData = new FormData() // Initialize FormData

    fields.forEach((field) => {
      if (field.type === FieldType.File) {
        // Append the file to FormData
        if (data[field.fieldName]) {
          formData.append(field.fieldName, data[field.fieldName][0]) // Assuming single file upload
        }
      } else {
        // Append other fields to FormData
        formData.append(field.fieldName, data[field.fieldName])
      }
    })

    onSubmit(formData) // Pass FormData to onSubmit handler
    onClose()
    reset()
  }

  return (
    <>
      <IconButton
        aria-label='Add'
        icon={<AddIcon />}
        onClick={onOpen}
        colorScheme='brand'
        isRound
      />
      <Modal isOpen={isOpen} size='xl' onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Crear Nuevo {objectFriendlyName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box as='form' onSubmit={handleSubmit(handleFormSubmit)}>
              {fields.map((field, index) => (
                <FormControl key={index} mb={4} isRequired={field.isRequired}>
                  <FormLabel>{field.friendlyUserName}</FormLabel>
                  <Controller
                    name={field.fieldName}
                    control={control}
                    defaultValue={
                      field.type === FieldType.Picklist && field.options?.length
                        ? field.options[0]
                        : ''
                    }
                    rules={{required: field.isRequired}}
                    render={({field: controllerField}) => {
                      switch (field.type) {
                        case FieldType.Number:
                          return <Input type='number' {...controllerField} />
                        case FieldType.Decimal:
                          return <Input type='number' step='any' {...controllerField} />
                        case FieldType.Picklist:
                          return (
                            <Select {...controllerField}>
                              {field.options?.map((option, i) => (
                                <option key={i} value={option}>
                                  {option}
                                </option>
                              ))}
                            </Select>
                          )
                        case FieldType.Lookup:
                          return (
                            <DynamicPicklist
                              isRequired={field.isRequired}
                              controlField={controllerField}
                              fetchOptions={field.fetchLookupData!}
                            />
                          )
                        case FieldType.File:
                          return <Input type='file' {...controllerField} />
                        case FieldType.RichText:
                          return (
                            <ReactQuill
                              value={controllerField.value}
                              onChange={controllerField.onChange}
                              style={{height: '100%', width: '100%'}}
                            />
                          )
                        default:
                          return <Input type='text' {...controllerField} />
                      }
                    }}
                  />
                </FormControl>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant='ghost' onClick={onClose}>
              Cancelar
            </Button>
            <Button colorScheme='brand' mr={3} onClick={handleSubmit(handleFormSubmit)}>
              Crear
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CreateModal
