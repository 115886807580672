import axios from 'axios'
import {Paginated, PaginatedRequestParams} from 'shared/components/DynamicTable/models'
import {
  Advertising,
  AdvertisingImage,
  AdvertisingLineItem,
  Author,
  Blog,
  Episode,
} from 'shared/models/NestModels'

const API_URL = process.env.REACT_APP_API_URL
export const GET_ASSEMBLIES_URL = `${API_URL}/assembly/by-community`
export const GET_BLOG_BY_TYPE = `${API_URL}/blog/by-type`

//----------- PAGINATED REQUESTS ------------
export function createPaginatedRequest<T>(url: string) {
  return async (params: PaginatedRequestParams & Record<string, any>): Promise<Paginated<T>> => {
    const response = await axios.get<Paginated<T>>(url, {params})
    return response.data
  }
}

export const getAllAuthors_paginated = createPaginatedRequest<Author>(`${API_URL}/author/get-all`)

export const getBlogsByType_paginated = createPaginatedRequest<Blog>(`${API_URL}/blog/by-type`)

export const getEpisodes_paginated = createPaginatedRequest<Episode>(`${API_URL}/episode`)

export const getAdvertising_paginated = createPaginatedRequest<Advertising>(
  `${API_URL}/advertising/all`
)

//------------------------------------------

//----------- CREATE REQUESTS ------------

export function createAuthor(data: Author) {
  return axios.put(`${API_URL}/author`, data)
}

export function createAdvertising(data: Author) {
  return axios.put(`${API_URL}/advertising`, data)
}

export function updateAdvertising(data: any, id: string) {
  return axios.put(`${API_URL}/advertising`, data, {params: {id}})
}

export function updateAdvertisingCountry(id: string, metadata: any, country: string) {
  return axios.put(`${API_URL}/advertising/by-country`, metadata, {params: {id, country}})
}

export function createBlog(data: Blog) {
  return axios.put(`${API_URL}/blog`, data)
}

export function createEpisode(data: Episode) {
  return axios.put(`${API_URL}/episode`, data)
}

export function updateBlog(data: any, id: string) {
  return axios.put(`${API_URL}/blog`, data, {params: {id}})
}

export function updateEpisode(data: any, id: string) {
  return axios.put(`${API_URL}/episode`, data, {params: {id}})
}

//----------- GET REQUESTS ------------

export function getArticle(id: string) {
  return axios.get<Blog>(`${API_URL}/blog`, {params: {id}})
}

export function getAdvertising(id: string) {
  return axios.get<Advertising>(`${API_URL}/advertising`, {params: {id}})
}

export function getEpisode(id: string) {
  return axios.get<Episode>(`${API_URL}/episode/by-id`, {params: {id}})
}

export function getAdvertisingByCountry(id: string, country: string) {
  return axios.get<{id: string; metadata: string}>(`${API_URL}/advertising/by-country`, {
    params: {id, country},
  })
}
