import React, {useState} from 'react'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Stack,
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
} from '@chakra-ui/react'
import {Image} from '@chakra-ui/image'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {RegistrationFormInputs} from 'modules/auth/_models'
import {registerCgn} from 'modules/auth/_requests'

const Registration = () => {
  const [signUpError, setSignUpError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: {errors},
    getValues,
  } = useForm<RegistrationFormInputs>()

  const onSubmit = async (data: RegistrationFormInputs) => {
    setIsLoading(true)
    try {
      const response = await registerCgn(data)
      navigate('/verify', {state: {email: data.email}})
    } catch (error: any) {
      console.error('Registration error:', error)
      setSignUpError(error.response?.data?.message || 'An error occurred during sign up.')
    }
    setIsLoading(false)
  }

  return (
    <Stack minH={'100vh'} direction={{base: 'column', md: 'row'}}>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <Stack spacing={4} w={'full'} maxW={'md'}>
          <Heading fontSize='2xl' fontWeight={600} textAlign='center'>
            Registrate
          </Heading>
          <Box position='relative' padding='2'></Box>
          {signUpError && (
            <Alert status='error' mb={4}>
              <AlertIcon />
              <AlertDescription>{signUpError}</AlertDescription>
              <CloseButton
                position='absolute'
                right='8px'
                top='8px'
                onClick={() => setSignUpError(null)}
              />
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex gap={6}>
              <FormControl isInvalid={!!errors.firstName}>
                <FormLabel htmlFor='firstName'>Nombre Completo</FormLabel>
                <Input
                  id='firstName'
                  type='text'
                  {...register('firstName', {
                    required: 'Nombre es requerido',
                  })}
                />
                <FormErrorMessage>{errors.firstName && errors.firstName.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.lastName}>
                <FormLabel htmlFor='lastName'>Apellidos</FormLabel>
                <Input
                  id='lastName'
                  type='text'
                  {...register('lastName', {
                    required: 'Apellido es requerido',
                  })}
                />
                <FormErrorMessage>{errors.lastName && errors.lastName.message}</FormErrorMessage>
              </FormControl>
            </Flex>

            <FormControl isInvalid={!!errors.email} mt={4}>
              <FormLabel htmlFor='email'>Email</FormLabel>
              <Input
                id='email'
                type='email'
                {...register('email', {
                  required: 'Email es requerido',
                  pattern: {
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                    message: 'Email no es válido',
                  },
                })}
              />
              <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.password} mt={4}>
              <FormLabel htmlFor='password'>Contraseña</FormLabel>
              <Input
                id='password'
                type='password'
                {...register('password', {
                  required: 'Contraseña es requerida',
                  minLength: {
                    value: 8,
                    message: 'La contraseña debe tener al menos 8 caracteres',
                  },
                })}
              />
              <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.confirmPassword} mt={4}>
              <FormLabel htmlFor='confirmPassword'>Confirmar Contraseña</FormLabel>
              <Input
                id='confirmPassword'
                type='password'
                {...register('confirmPassword', {
                  required: 'Confirmar contraseña es requerido',
                  validate: (value) =>
                    value === getValues('password') || 'Las contraseñas no coinciden',
                })}
              />
              <FormErrorMessage>
                {errors.confirmPassword && errors.confirmPassword.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.documentType} mt={4}>
              <FormLabel htmlFor='documentType'>Tipo de documento</FormLabel>
              <Select
                id='documentType'
                {...register('documentType', {required: 'Tipo de documento es requerido'})}
              >
                <option value='DNI'>DNI</option>
                <option value='Carn Ext'>Carn Ext</option>
                <option value='Pasaporte'>Pasaporte</option>
              </Select>
              <FormErrorMessage>
                {errors.documentType && errors.documentType.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.documentNumber} mt={4}>
              <FormLabel htmlFor='documentNumber'>Número de Documento</FormLabel>
              <Input
                id='documentNumber'
                type='text'
                {...register('documentNumber', {
                  required: 'Número de documento es requerido',
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Número de documento debe ser numérico',
                  },
                })}
              />
              <FormErrorMessage>
                {errors.documentNumber && errors.documentNumber.message}
              </FormErrorMessage>
            </FormControl>

            <Button
              isLoading={isLoading}
              w='100%'
              mt={4}
              type='submit'
              color={'white'}
              bg={'brand.300'}
              _hover={{bg: 'brand.400'}}
              _focus={{bg: 'brand.500'}}
            >
              Registrarse
            </Button>
            <Button
              onClick={() => navigate('/login')}
              w='100%'
              mt={4}
              color={'white'}
              bg={'brand.300'}
              _hover={{bg: 'brand.400'}}
              _focus={{bg: 'brand.500'}}
            >
              Ir a Login
            </Button>
          </form>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image alt={'Login Image'} objectFit={'cover'} src={'./assets/login_nature.webp'} />
      </Flex>
    </Stack>
  )
}

export default Registration
