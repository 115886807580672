import {
  Avatar,
  Box,
  BoxProps,
  CloseButton,
  Divider,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'
import {signOut} from 'aws-amplify/auth'
import {useAuth} from 'modules/auth/Auth'
import React, {useEffect, useState} from 'react'
import {FiBell, FiChevronDown, FiMenu} from 'react-icons/fi'
import {Link, useNavigate} from 'react-router-dom'
import DynamicNavMenu from './DynamicNavMenu'
import {AppMetadata} from './_models'
import {navigationData} from 'app/routing/PrivateRoutes'
import {AddIcon} from '@chakra-ui/icons'
import {BiLogOut} from 'react-icons/bi'
import {BsPerson} from 'react-icons/bs'

interface MobileProps extends FlexProps {
  onOpen: () => void
}

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const SidebarContent = ({onClose, ...rest}: SidebarProps) => {
  const [appMtd, setAppMtd] = useState<AppMetadata>({
    data: navigationData,
  } as AppMetadata)
  const [isSelectedSet, setIsSelectedSet] = useState<boolean>(false)

  useEffect(() => {
    if (location) {
      const locArr = location.pathname.split('/')
      updateSidebar(locArr[2], locArr[3])
    }
  }, [location])

  const updateSidebar = (parentId: string, childId: string) => {
    const updatedData = appMtd.data.map((it) => ({
      ...it,
      isSelected: it.id === parentId,
      subMenu: it.subMenu?.map((mp) => ({
        ...mp,
        isSelected: it.id === parentId && mp.id === childId,
      })),
    }))
    setIsSelectedSet(true)
    setAppMtd({
      ...appMtd,
      data: updatedData,
    })
  }

  return (
    <Box
      transition='3s ease'
      bg={useColorModeValue('brand.100', 'gray.900')}
      borderRight='1px'
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{base: 'full', md: 72}}
      pos='fixed'
      h='full'
      overflowY='auto'
      {...rest}
    >
      <Flex h='20' alignItems='center' mx='8' justifyContent='center' p='1rem' position='relative'>
        <Link to={'/'} style={{height: '100%'}}>
          <Image
            alt='Logo Image'
            objectFit={'cover'}
            src='https://apiwp.arcovia.org/wp-content/uploads/2024/02/Logo-Arcovia-Viajes-y-turismo-e1718387409883.png'
            w='100%'
          />
        </Link>
        <Show below='md'>
          <CloseButton position='absolute' right={0} onClick={onClose} />
        </Show>
      </Flex>
      <Divider />
      <Box ml={3} mr={3}>
        {appMtd && isSelectedSet && <DynamicNavMenu navigationMetadata={appMtd} onClose={onClose} />}
      </Box>
    </Box>
  )
}

const MobileNav = ({onOpen, ...rest}: MobileProps) => {
  const {logout, currentUser} = useAuth()
  const navigate = useNavigate()

  const handleSignOff = async () => {
    // TODO: Implement remote logout
    // const lgout = await logout();
    //Remove Cookies
    logout()
    signOut().then(() => {
      navigate('/')
      navigate(0)
    })
  }

  return (
    <Flex
      ml={{base: 0, md: 60}}
      px={{base: 4, md: 4}}
      height='20'
      alignItems='center'
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth='1px'
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{base: 'space-between', md: 'flex-end'}}
      {...rest}
    >
      <IconButton
        display={{base: 'flex', md: 'none'}}
        onClick={onOpen}
        variant='outline'
        aria-label='open menu'
        icon={<FiMenu />}
      />

      <Image
        display={{base: 'flex', md: 'none'}}
        alt='Logo Image'
        objectFit={'cover'}
        src='https://apiwp.arcovia.org/wp-content/uploads/2024/02/Logo-Arcovia-Viajes-y-turismo-e1718387409883.png'
        height='100%'
        p={4}
      />

      <HStack spacing={{base: '0', md: '6'}}>
        <IconButton size='lg' variant='ghost' aria-label='open menu' icon={<FiBell />} />
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition='all 0.3s' _focus={{boxShadow: 'none'}}>
              <HStack>
                <Avatar size={'sm'} name={currentUser?.fullname} />
                <VStack
                  display={{base: 'none', md: 'flex'}}
                  alignItems='flex-start'
                  spacing='1px'
                  ml='2'
                >
                  <Text fontSize='sm'>{currentUser?.fullname}</Text>
                  {/* <Text fontSize='xs' color='gray.600'>
                    {currentUser?.email}
                  </Text> */}
                </VStack>
                <Box display={{base: 'none', md: 'flex'}}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              {/* <MenuItem>Profile</MenuItem> */}
              {/* <MenuItem>Settings</MenuItem> */}
              {/* <MenuItem>Billing</MenuItem> */}
              {/* <MenuDivider /> */}
              <MenuItem onClick={() => navigate('/profile')}>
                <Icon as={BsPerson} fontSize='3xl' pr={2} />
                Mi Perfil
              </MenuItem>
              <MenuItem onClick={handleSignOff}>
                <Icon as={BiLogOut} fontSize='3xl' pr={2} />
                Cerrar sesión
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}

export {MobileNav, SidebarContent}
