import React, {useEffect, useState} from 'react'
import {Box, VStack, Skeleton, HStack, Text, Divider, Button} from '@chakra-ui/react'
import {getAdvertising, getAdvertisingByCountry} from 'shared/requests/app-requests'
import TreeView from '../TreeView'
import { Advertising, AdvertisingLineItem } from 'shared/models/NestModels'

interface AdvertisingViewProps {
  id: string
}

const countryOptions = [
  {label: 'Default', value: 'default'},
  {label: 'Perú', value: 'PE'},
  {label: 'México', value: 'MX'},
  {label: 'Argentina', value: 'AR'},
]

const AdvertisingView = ({id}: AdvertisingViewProps) => {
  const [advertising, setAdvertising] = useState<Advertising | null>(null)
  const [loading, setLoading] = useState(true)
  const [selectedCountry, setSelectedCountry] = useState('default')
  const [pageLocationurl, setPageLocationurl] = useState('')
  const [description, setDescription] = useState('')
  const [advertisingLineItems, setAdvertisingLineItems] = useState<AdvertisingLineItem[]>([])

  useEffect(() => {
    getAdvertising(id).then((response) => {
      const {pageLocationurl, description} = response.data
      setAdvertising(response.data)
      setPageLocationurl(pageLocationurl || '')
      setDescription(description || '')
      setLoading(false)

      loadAdvertisingByCountry('default')
    })
  }, [id])

  const loadAdvertisingByCountry = (country: string) => {
    setLoading(true)
    getAdvertisingByCountry(id, country)
      .then((response) => {
        const {metadata} = response.data
        setAdvertisingLineItems(JSON.parse(metadata))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    loadAdvertisingByCountry(selectedCountry)
  }, [selectedCountry])

  const handleCountrySelect = (country: string) => {
    setSelectedCountry(country)
  }

  return (
    <Box padding='6' boxShadow='lg' bg='white'>
      {loading ? (
        <VStack spacing={4}>
          <Skeleton height='20px' width='100%' />
          <Skeleton height='20px' width='100%' />
          <Skeleton height='40px' width='100%' />
          <Skeleton height='300px' width='100%' />
          <Skeleton height='40px' width='100%' />
        </VStack>
      ) : (
        <>
          <Box>
            <Text fontWeight={700}>URL de la Página</Text>
            <Text>{pageLocationurl}</Text>

            <Text fontWeight={700} pt={6}>
              Descripción
            </Text>
            <Text>{description}</Text>
          </Box>
          <Divider pt={6} />

          <HStack spacing={4} mb={4} pt={6}>
            {countryOptions.map((option) => (
              <Button
                key={option.value}
                bgColor={selectedCountry === option.value ? 'brand.300' : 'lightgray'}
                colorScheme='brand'
                onClick={() => handleCountrySelect(option.value)}
              >
                {option.label}
              </Button>
            ))}
          </HStack>

          <VStack spacing={4} align='start'>
            <TreeView data={advertisingLineItems} />
          </VStack>
        </>
      )}
    </Box>
  )
}

export default AdvertisingView
