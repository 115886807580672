import {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import * as React from 'react'
import {Navigate, Route, Routes} from 'react-router'
import Registration from 'shared/pages/Registration/Registration'
import LoginContainer from 'shared/pages/AuthenticatonPages/LoginPage/Login'
import ForgotPassword from 'shared/pages/AuthenticatonPages/ForgotPassword/ForgotPassword'
import {AuthLayout} from './AuthLayout'
import LayoutSplashScreen from './LayoutSplashScreen'
import {LOGOUT} from 'app/store/reducer'
import {store} from 'app/store/store'
import Verify from 'shared/pages/AuthenticatonPages/Verify/AuthVerify'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

interface AppProviderInterface {
  children: ReactNode
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {
    //Do Nothing
  },
  currentUser: undefined,
  setCurrentUser: () => {
    //Do Nothing
  },
  logout: () => {
    //Do Nothing
  },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider = ({children}: AppProviderInterface) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    store.dispatch({type: LOGOUT})
    sessionStorage.clear()
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit = ({children}: AppProviderInterface) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(apiToken)
          if (data) {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {
      requestUser(auth.api_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
  }, [])
  // TODO: Enable SplashScreen
  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<LoginContainer />} />
      <Route path='register' element={<Registration />} />
      <Route path='verify' element={<Verify />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='*' element={<Navigate to='login' />} />
      <Route index element={<LoginContainer />} />
    </Route>
  </Routes>
)

const Logout = () => {
  const {logout} = useAuth()
  useEffect(() => {
    logout()
    document.location.reload()
  }, [logout])

  return (
    <Routes>
      <Navigate to='/auth/login' />
    </Routes>
  )
}

export {AuthProvider, AuthInit, useAuth, AuthPage, Logout}
