import React, {useEffect, useState} from 'react'
import {
  Box,
  Heading,
  Text,
  Image,
  VStack,
  Skeleton,
  CardHeader,
  CardBody,
  SimpleGrid,
} from '@chakra-ui/react'
import {getArticle} from 'shared/requests/app-requests'
import {Blog} from 'shared/models/NestModels'

interface ArticleViewProps {
  id: string
}

const ArticleView = ({id}: ArticleViewProps) => {
  const [article, setArticle] = useState<Blog | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getArticle(id).then((response) => {
      setArticle(response.data)
      setLoading(false)
    })
  }, [id])

  if (loading) {
    return (
      <Box padding='6' boxShadow='lg' bg='white'>
        <Skeleton height='40px' />
        <Skeleton height='200px' mt='4' />
        <Skeleton height='20px' mt='4' />
        <Skeleton height='20px' mt='4' />
        <Skeleton height='20px' mt='4' />
      </Box>
    )
  }

  return (
    <>
      <CardHeader fontSize='xx-large'>{article?.name}</CardHeader>
      <CardBody>
        <SimpleGrid columns={[1, 2]} spacing={10}>
          <VStack align='start' spacing={4}>
            <Text fontSize='md'>
              <strong>Name:</strong>
            </Text>
            <Text>{article?.name}</Text>

            <Text fontSize='md'>
              <strong>Created Date:</strong>
            </Text>
            <Text>
              {article?.createdDate
                ? new Date(article.createdDate).toLocaleDateString()
                : 'Unknown Date'}
            </Text>

            <Text fontSize='md'>
              <strong>Type:</strong>
            </Text>
            <Text>{article?.type}</Text>

            <Text fontSize='md'>
              <strong>Slug:</strong>
            </Text>
            <Text>{article?.slug}</Text>
          </VStack>

          <VStack align='start' spacing={4}>
            {article?.headerImg && (
              <Box>
                <Text fontSize='md'>
                  <strong>Header Image:</strong>
                </Text>
                <Image
                  src={article.headerImg}
                  alt={article.name}
                  borderRadius='md'
                  objectFit='cover'
                  height='200px'
                  width='100%'
                />
              </Box>
            )}

            <Text fontSize='md'>
              <strong>Created By:</strong>
            </Text>
            <Text>{article?.createdBy || 'N/A'}</Text>

            <Text fontSize='md'>
              <strong>Last Modified By:</strong>
            </Text>
            <Text>{article?.lastModifiedBy || 'N/A'}</Text>

            <Text fontSize='md'>
              <strong>Last Modified Date:</strong>
            </Text>
            <Text>
              {article?.lastModifiedDate
                ? new Date(article.lastModifiedDate).toLocaleDateString()
                : 'Unknown Date'}
            </Text>
          </VStack>
        </SimpleGrid>

        <VStack align='start' spacing={4} mt={8}>
          <Text fontSize='md'>
            <strong>Excerpt:</strong>
          </Text>
          <Box dangerouslySetInnerHTML={{__html: article?.excerpt || ''}} />

          {article?.content && (
            <>
              <Text fontSize='md' mt={4}>
                <strong>Content:</strong>
              </Text>
              <Box dangerouslySetInnerHTML={{__html: article.content}} />
            </>
          )}
        </VStack>
      </CardBody>
    </>
  )
}

export default ArticleView
