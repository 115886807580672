import React, {useState} from 'react'
import {
  Box,
  Button,
  Collapse,
  VStack,
  Input,
  FormControl,
  FormLabel,
  IconButton,
  HStack,
  Checkbox,
  SimpleGrid,
  Image,
  Text,
} from '@chakra-ui/react'
import {ChevronDownIcon, ChevronRightIcon, DeleteIcon, AddIcon} from '@chakra-ui/icons'
import CustomMultiSelect from 'shared/components/CustomMultiSelect/CustomMultiSelect'
import countries from 'shared/json/countries'
import {AdvertisingLineItem} from 'shared/models/NestModels'

interface TreeViewProps {
  data: AdvertisingLineItem[]
  onDataChange?: (data: AdvertisingLineItem[]) => void
}

const TreeView: React.FC<TreeViewProps> = ({data, onDataChange}) => {
  const [expandedItems, setExpandedItems] = useState<{[key: string]: boolean}>({})

  const toggleItem = (id: string) => {
    setExpandedItems({...expandedItems, [id]: !expandedItems[id]})
  }

  const handleImageChange = (
    lineItemIndex: number,
    imageIndex: number,
    field: string,
    value: any
  ) => {
    if (onDataChange === undefined) return
    const newData = [...data]
    newData[lineItemIndex].images[imageIndex] = {
      ...newData[lineItemIndex].images[imageIndex],
      [field]: value,
    }
    onDataChange(newData)
  }

  const handleAddImage = (lineItemIndex: number) => {
    if (onDataChange === undefined) return

    const newData = [...data]
    newData[lineItemIndex].images.push({
      id: String(new Date().getTime()),
      name: '',
      imageUrl: '',
      redirectUrl: '',
    })
    onDataChange(newData)
  }

  const handleRemoveImage = (lineItemIndex: number, imageIndex: number) => {
    if (onDataChange === undefined) return

    const newData = [...data]
    newData[lineItemIndex].images.splice(imageIndex, 1)
    onDataChange(newData)
  }

  return (
    <VStack align='start' spacing={4}>
      {data.map((item, lineItemIndex) => (
        <Box key={item.position} width='100%'>
          <HStack justifyContent='space-between'>
            <Text variant='link' onClick={() => toggleItem(item.position)} size='sm'>
              {expandedItems[item.position] ? (
                <ChevronDownIcon boxSize='1.5rem' />
              ) : (
                <ChevronRightIcon boxSize='1.5rem' />
              )}
              {item.name}
            </Text>
            {onDataChange && (
              <IconButton
                aria-label='Add Image'
                icon={<AddIcon />}
                size='sm'
                onClick={() => handleAddImage(lineItemIndex)}
              />
            )}
          </HStack>
          <Collapse in={expandedItems[item.position]} animateOpacity>
            <SimpleGrid columns={[1, 2]} spacing={4} pl={8} pt={2}>
              {item.images.map((image, imageIndex) => (
                <Box key={image.id} border='1px' borderColor='gray.200' p={4} borderRadius='md'>
                  <HStack justify='space-between'>
                    <FormLabel>{`Image ${imageIndex + 1}`}</FormLabel>
                    {onDataChange && (
                      <IconButton
                        aria-label='Remove Image'
                        icon={<DeleteIcon />}
                        size='sm'
                        colorScheme='red'
                        onClick={() => handleRemoveImage(lineItemIndex, imageIndex)}
                      />
                    )}
                  </HStack>
                  {image.imageUrl ? (
                    <Image
                      src={image.imageUrl}
                      alt={image.name}
                      objectFit='cover'
                      width='auto'
                      maxH='150px'
                      borderRadius='md'
                      border='1px solid gray'
                    />
                  ) : (
                    <Box
                      width='150px'
                      height='150px'
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      borderRadius='md'
                      border='1px solid gray'
                    >
                      <Text color='gray.500'>No Image</Text>
                    </Box>
                  )}
                  <VStack spacing={4}>
                    <FormControl>
                      <FormLabel>Image Name</FormLabel>
                      <Input
                        value={image.name}
                        onChange={(e) =>
                          handleImageChange(lineItemIndex, imageIndex, 'name', e.target.value)
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Image URL</FormLabel>
                      <Input
                        value={image.imageUrl}
                        onChange={(e) =>
                          handleImageChange(lineItemIndex, imageIndex, 'imageUrl', e.target.value)
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Redirect URL</FormLabel>
                      <Input
                        value={image.redirectUrl}
                        onChange={(e) =>
                          handleImageChange(
                            lineItemIndex,
                            imageIndex,
                            'redirectUrl',
                            e.target.value
                          )
                        }
                      />
                    </FormControl>
                  </VStack>
                </Box>
              ))}
            </SimpleGrid>
          </Collapse>
        </Box>
      ))}
    </VStack>
  )
}

export default TreeView
