import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Icon,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import {Link} from 'react-router-dom'
import {AppMetadata} from './_models'
import {FcCancel, FcDisclaimer, FcDonate, FcHome, FcNeutralDecision, FcUpload} from 'react-icons/fc'

interface DynamicNavMenuInterface {
  navigationMetadata?: AppMetadata | null
  onClose: () => void
}

const icons = {
  house: FcHome,
  ask: FcNeutralDecision,
  upload: FcUpload,
  removeConsent: FcDisclaimer,
  remove: FcCancel,
  donate: FcDonate,
}

const DynamicNavMenu = ({navigationMetadata, onClose}: DynamicNavMenuInterface) => {
  const findSelectedParentIndex = (): number[] | undefined => {
    if (navigationMetadata?.data) {
      const itemsWithSubMenu = navigationMetadata?.data?.filter((parent) => parent.subMenu)
      const index = itemsWithSubMenu?.findIndex((parent) => parent.isSelected)
      return index !== -1 ? [index] : undefined
    }
    return undefined
  }

  return (
    <Accordion defaultIndex={findSelectedParentIndex()} pt={6} allowMultiple>
      <Box gap={4}>
        {navigationMetadata?.data?.map((parentMenu) => (
          <AccordionItem border={'none'} key={parentMenu.id} onClick={() => onClose()}>
            {parentMenu.subMenu == null ? (
              <Link to={`/${parentMenu.path}`}>
                <Button
                  leftIcon={
                    <Icon boxSize={5} mr={2} as={icons[parentMenu.icon as keyof typeof icons]} />
                  }
                  pl={2}
                  color={parentMenu.isSelected ? 'brand.300' : 'none'}
                  justifyContent={'flex-start'}
                  w={'100%'}
                  h={'56px'}
                  _hover={{backgroundColor: 'sidebar.hover'}}
                  variant='ghost'
                >
                  {parentMenu.label}
                </Button>
              </Link>
            ) : (
              <AccordionButton
                pl={2}
                _hover={{backgroundColor: 'sidebar.hover', borderRadius: 'md'}}
                justifyContent={'flex-start'}
                w={'100%'}
              >
                <Icon boxSize={5} mr={2} as={icons[parentMenu.icon as keyof typeof icons]} />
                <Text>{parentMenu.label}</Text>
                <AccordionIcon ml={'auto'} />
              </AccordionButton>
            )}
            <AccordionPanel pb={4}>
              {parentMenu?.subMenu?.map((subMenu) => (
                <Box key={subMenu.id}>
                  <Link to={`/`}>
                    <Button
                      pl={6}
                      color={subMenu.isSelected ? 'brand.300' : 'none'}
                      justifyContent={'flex-start'}
                      w={'100%'}
                      _hover={{backgroundColor: 'sidebar.hover'}}
                      variant='ghost'
                    >
                      {subMenu.label}
                    </Button>
                  </Link>
                </Box>
              ))}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Box>
    </Accordion>
  )
}

export default DynamicNavMenu
