import {Box, Center, Flex, Image, Spinner} from '@chakra-ui/react'
import React from 'react'

const LayoutSplashScreen = () => {
  return (
    <Center h='100vh' flexDir='column'>
      <Image alt='Logo' src='https://apiwp.arcovia.org/wp-content/uploads/2024/02/Logo-Arcovia-Viajes-y-turismo-e1718387409883.png' height='92px' w='auto' />
      <Spinner
        variant=''
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='brand.300'
        size='xl'
      ></Spinner>
    </Center>
  )
}

export default LayoutSplashScreen
