import React from 'react'
import ReactDOM from 'react-dom/client'
import * as serviceWorker from './serviceWorker'
import {AppRoutes} from 'app/routing/AppRoutes'
import {ChakraProvider, createStandaloneToast, extendTheme} from '@chakra-ui/react'
import {Amplify} from 'aws-amplify'
import config from './aws-exports'

const colors = {
  brand: {
    100: "#f2f3f5",
    300: "#DF8D2A",
    400: "#E1A75C",
    500: '#868EEB',
    900: "#191A1E",
  },
  sidebar: {
    hover: '#dbdbdb',
  },
}

const {ToastContainer} = createStandaloneToast()

const theme = extendTheme({colors})
Amplify.configure(config)
// setupAxios(axios)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <AppRoutes />
      <ToastContainer />
    </ChakraProvider>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
