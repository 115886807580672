import React from 'react'
import {Box, Flex, Heading, Text, Button, Image, Center} from '@chakra-ui/react'
import {useNavigate} from 'react-router-dom'
import {FcVlc} from 'react-icons/fc'

const UnderConstruction = () => {
  const navigate = useNavigate()

  return (
    <Flex align='center' justify='center' direction='column' p={8}>
      <Box textAlign='center' maxW='md'>
        <Center>
          <FcVlc size='12em' color='teal' />
        </Center>
        <Heading as='h1' size='xl' mb={4}>
          Página en construcción
        </Heading>
        <Text fontSize='lg' mb={8}>
          Estamos trabajando para mejorar tu experiencia. Vuelve pronto!
        </Text>
        <Button colorScheme='teal' size='lg' onClick={() => navigate('/')}>
          Regresar a Inicio
        </Button>
      </Box>
    </Flex>
  )
}

export default UnderConstruction
