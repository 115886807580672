import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import * as React from 'react'
import {PrivateRoutes} from './PrivateRoutes'
import App from 'App'
import {ErrorsPage} from 'shared/pages/ErrorPage/ErrorsPage'
import {Logout, AuthPage} from 'modules/auth/Auth'
import {fetchAuthSession} from 'aws-amplify/auth'
import axios from 'axios'
import {setupAxiosWithToken} from 'modules/auth/AuthHelpers'
import LayoutSplashScreen from 'modules/auth/LayoutSplashScreen'
import {EmailProvider} from 'shared/pages/AuthenticatonPages/LoginContext/EmailContext'

const {PUBLIC_URL} = process.env

const AppRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>()

  React.useEffect(() => {
    fetchAuthSession()
      .then((auth) => {
        if (auth?.tokens?.idToken) {
          setIsAuthenticated(true)
          setupAxiosWithToken(auth.tokens.idToken.toString(), axios)
        }
      })
      .catch(() => {
        setIsAuthenticated(false)
      })
  }, [])

  if (isAuthenticated == null) {
    return <LayoutSplashScreen />
  }

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {isAuthenticated ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/home' />} />
            </>
          ) : (
            <>
              <Route path='/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='login' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
