import React from 'react'
import {Outlet} from 'react-router-dom'
import {MobileNav, SidebarContent} from 'shared/components/Sidebar/Sidebar'
import {Box, Drawer, DrawerContent, useColorModeValue, useDisclosure} from '@chakra-ui/react'

const MasterLayout = () => {
  const {isOpen, onOpen, onClose} = useDisclosure()

  return (
    <>
      <Box minH='100vh' bg={useColorModeValue('gray.50', 'gray.800')}>
        <SidebarContent onClose={() => onClose} display={{base: 'none', md: 'block'}} />
        <Drawer
          isOpen={isOpen}
          placement='left'
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size='full'
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{base: 0, md: 72}} p='4'>
          <Outlet />
        </Box>
      </Box>

      {/* begin:: Drawers */}
      {/* <ActivityDrawer />
      <RightToolbar />
      <DrawerMessenger /> */}
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      {/* <InviteUsers />
      <UpgradePlan /> */}
      {/* end:: Modals */}
    </>
  )
}

export {MasterLayout}
