import React, {useEffect, useState} from 'react'
import {Box, Button, VStack, Skeleton, useToast, HStack, Text, Divider} from '@chakra-ui/react'
import {useNavigate} from 'react-router-dom'
import {Advertising, AdvertisingLineItem} from 'shared/models/NestModels'
import {
  getAdvertising,
  getAdvertisingByCountry,
  updateAdvertisingCountry,
} from 'shared/requests/app-requests'
import TreeView from '../TreeView'

interface AdvertisingEditProps {
  id: string
}

const countryOptions = [
  {label: 'Default', value: 'default'},
  {label: 'Perú', value: 'PE'},
  {label: 'México', value: 'MX'},
  {label: 'Argentina', value: 'AR'},
]

const AdvertisingEdit = ({id}: AdvertisingEditProps) => {
  const [advertising, setAdvertising] = useState<Advertising | null>(null)
  const [loading, setLoading] = useState(true)
  const [selectedCountry, setSelectedCountry] = useState('default')
  const [selectedCountries, setSelectedCountries] = useState<string[]>([])
  const [pageLocationurl, setPageLocationurl] = useState('')
  const [description, setDescription] = useState('')
  const [advertisingLineItems, setAdvertisingLineItems] = useState<AdvertisingLineItem[]>([])
  const toast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    getAdvertising(id).then((response) => {
      const {country, pageLocationurl, description} = response.data
      setAdvertising(response.data)
      setSelectedCountries(country?.split(',') || [])
      setPageLocationurl(pageLocationurl || '')
      setDescription(description || '')
      setLoading(false)

      loadAdvertisingByCountry('default')
    })
  }, [id])

  const loadAdvertisingByCountry = (country: string) => {
    setLoading(true)
    getAdvertisingByCountry(id, country)
      .then((response) => {
        const {metadata} = response.data
        setAdvertisingLineItems(JSON.parse(metadata))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    loadAdvertisingByCountry(selectedCountry)
  }, [selectedCountry])

  const handleCountrySelect = (country: string) => {
    setSelectedCountry(country)
  }

  const handleSave = () => {
    updateAdvertisingCountry(id, advertisingLineItems, selectedCountry).then((response) => {
      toast({
        title: 'Publicidad actualizada.',
        description: 'La publicidad ha sido actualizada con éxito.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      navigate(`/advertising/view/${id}`)
    })
  }

  return (
    <Box padding='6' boxShadow='lg' bg='white'>
      {loading ? (
        <VStack spacing={4}>
          <Skeleton height='20px' width='100%' />
          <Skeleton height='20px' width='100%' />
          <Skeleton height='40px' width='100%' />
          <Skeleton height='300px' width='100%' />
          <Skeleton height='40px' width='100%' />
        </VStack>
      ) : (
        <>
          <Box>
            <Text fontWeight={700}>URL de la Página</Text>
            <Text>{pageLocationurl}</Text>

            <Text fontWeight={700} pt={6}>
              Descripción
            </Text>
            <Text>{description}</Text>
          </Box>
          <Divider pt={6} />

          <HStack spacing={4} mb={4} pt={6}>
            {countryOptions.map((option) => (
              <Button
                key={option.value}
                bgColor={selectedCountry === option.value ? 'brand.300' : 'lightgray'}
                colorScheme='brand'
                onClick={() => handleCountrySelect(option.value)}
              >
                {option.label}
              </Button>
            ))}
          </HStack>

          <form onSubmit={(e) => e.preventDefault()}>
            <VStack spacing={4} align='start'>
              <TreeView data={advertisingLineItems} onDataChange={setAdvertisingLineItems} />
              <Button colorScheme='brand' backgroundColor='brand.300' onClick={handleSave}>
                Guardar
              </Button>
            </VStack>
          </form>
        </>
      )}
    </Box>
  )
}

export default AdvertisingEdit
