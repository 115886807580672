import React, {useEffect, useState, useCallback, useRef} from 'react'
import {
  Box,
  FormControl,
  Input,
  List,
  ListItem,
  Skeleton,
  useToast,
  InputGroup,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react'
import {ControllerRenderProps} from 'react-hook-form'
import {PaginatedRequestParams, Paginated} from 'shared/components/DynamicTable/models'
import {ChevronDownIcon} from '@chakra-ui/icons'

interface DynamicPicklistProps {
  isRequired: boolean
  controlField: ControllerRenderProps
  fetchOptions: (params: PaginatedRequestParams & Record<string, any>) => Promise<Paginated<any>>
}

const DynamicPicklist: React.FC<DynamicPicklistProps> = ({
  isRequired,
  controlField,
  fetchOptions,
}) => {
  const [options, setOptions] = useState<{id: string; name: string}[]>([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState(search)
  const [page, setPage] = useState(1)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const toast = useToast()
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search)
    }, 800)

    return () => {
      clearTimeout(handler)
    }
  }, [search])

  const loadOptions = useCallback(async () => {
    setLoading(true)
    try {
      const result = await fetchOptions({search: debouncedSearch || '', page, limit: 10})
      setOptions((prevOptions) => (page === 1 ? result.data : [...prevOptions, ...result.data]))
    } catch (error: any) {
      toast({
        title: 'Error loading options',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }, [debouncedSearch, page, fetchOptions, toast])

  useEffect(() => {
    loadOptions()
  }, [debouncedSearch, page, loadOptions])

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const {scrollTop, scrollHeight, clientHeight} = e.currentTarget
    if (scrollTop + clientHeight >= scrollHeight - 50) {
      setPage((prevPage) => prevPage + 1)
    }
  }

  const handleSelect = (option: {id: string; name: string}) => {
    controlField.onChange(option.id)
    setSearch(option.name)
    setIsDropdownOpen(false)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false)
    }
  }

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isDropdownOpen])

  return (
    <FormControl isRequired={isRequired}>
      <InputGroup>
        <Input
          type='text'
          value={search}
          onChange={(e) => {
            setSearch(e.target.value)
            setPage(1)
            setIsDropdownOpen(true)
          }}
          onFocus={() => setIsDropdownOpen(true)}
        />
        <InputRightElement>
          <IconButton
            icon={<ChevronDownIcon />}
            aria-label='Toggle Dropdown'
            variant='ghost'
            onClick={() => setIsDropdownOpen((prev) => !prev)}
          />
        </InputRightElement>
      </InputGroup>
      {isDropdownOpen && (
        <Box
          ref={dropdownRef}
          position='absolute'
          zIndex={1}
          maxH='200px'
          overflowY='auto'
          mt={2}
          borderWidth={1}
          borderRadius='md'
          bg='white'
          boxShadow='md'
          onScroll={handleScroll}
          width='100%'
        >
          {loading && page === 1 ? (
            <>
              <Skeleton height='20px' my={2} ml={2} mr={2} />
              <Skeleton height='20px' my={2} ml={2} mr={2} />
              <Skeleton height='20px' my={2} ml={2} mr={2} />
            </>
          ) : (
            <List>
              {options.map((option) => (
                <ListItem
                  key={option.id}
                  onClick={() => handleSelect(option)}
                  cursor='pointer'
                  p={2}
                  _hover={{backgroundColor: 'gray.100'}}
                >
                  {option.name}
                </ListItem>
              ))}
              {loading && page > 1 && <Skeleton height='20px' my={2} />}
            </List>
          )}
        </Box>
      )}
    </FormControl>
  )
}

export default DynamicPicklist
