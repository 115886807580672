// src/app/sessionStorage.ts
export const loadState = (): any => {
  try {
    const serializedState = sessionStorage.getItem('appState')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    console.error('Could not load state from sessionStorage', err)
    return undefined
  }
}

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state)
    sessionStorage.setItem('appState', serializedState)
  } catch (err) {
    console.error('Could not save state to sessionStorage', err)
  }
}
