import {Icon} from '@chakra-ui/react'
import * as React from 'react'
import {BsCloud, BsGrid1X2} from 'react-icons/bs'
import {FaGasPump} from 'react-icons/fa'
import {
  FcAutomatic,
  FcAutomotive,
  FcBullish,
  FcBusinessman,
  FcBusinesswoman,
  FcCommandLine,
  FcDeployment,
  FcFolder,
  FcHome,
  FcLandscape,
  FcLibrary,
} from 'react-icons/fc'
import {IoIosWater} from 'react-icons/io'
import {MdElectricBolt} from 'react-icons/md'

export enum IconType {
  House = 'house',
  Gear = 'gear',
  User = 'user',
  Account = 'account',
  Articles = 'articles',
}

const iconMap = {
  [IconType.House]: FcHome,
  [IconType.Gear]: FcCommandLine,
  [IconType.User]: FcBusinesswoman,
  [IconType.Account]: FcBusinessman,
  [IconType.Articles]: FcFolder,
}

type Props = {
  icon?: IconType
  size?: number
  color?: string
}

const DynamicIcon = ({icon, size = 7, color}: Props) => {
  if (!icon) return <></>
  if (!iconMap[icon]) return <BsGrid1X2 />

  const CompName = iconMap[icon]
  return <Icon as={CompName} boxSize={size} color={color} />
}

export default DynamicIcon
