import React, {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {Provider} from 'react-redux'
import {store} from 'app/store/store'
import {EmailProvider} from 'shared/pages/AuthenticatonPages/LoginContext/EmailContext'

// function App({signOut, user}: WithAuthenticatorProps) {
function App() {
  return (
    <Suspense fallback={<div />}>
      <Provider store={store}>
        <EmailProvider>
          <Outlet />
        </EmailProvider>
      </Provider>
    </Suspense>
  )
}

// export default withAuthenticator(App)
export default App
