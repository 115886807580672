import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AppMetadata} from 'shared/components/Sidebar/_models'
import {AccountProperty, MyCommunitiesModel} from 'shared/models/MyCommunitiesModel'

export const LOGOUT = 'LOGOUT'

export interface AppState {
  error: null | any
  accountProperty: null | AccountProperty
  appMetadata: null | AppMetadata
}

export const initialState: AppState = {
  appMetadata: null,
  accountProperty: null,
  error: null,
}

// Create a slice for the user state
const appSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setAppMetadata(state, action: PayloadAction<null | AppMetadata>) {
      state.appMetadata = action.payload
    },
    setAccountProperty(state, action: PayloadAction<null | AccountProperty>) {
      state.accountProperty = action.payload
    },
    setError(state, action: PayloadAction<null | string>) {
      state.error = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LOGOUT, () => initialState)
  },
})

export const {setAppMetadata, setAccountProperty, setError} = appSlice.actions

export default appSlice.reducer
