import React, {useState, useEffect} from 'react'
import {
  Center,
  Heading,
  Button,
  FormControl,
  Flex,
  Input,
  Stack,
  useColorModeValue,
  HStack,
  Alert,
  AlertIcon,
  PinInput,
  PinInputField,
  Image,
} from '@chakra-ui/react'
import {useForm, SubmitHandler} from 'react-hook-form'
import {verifyOneTimeToken} from 'modules/auth/_requests'
import {useLocation, useNavigate} from 'react-router-dom'

interface FormValues {
  verificationCode: string[]
  email: string
}

const Verify: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as any
  const initialEmail = state?.email || ''
  const [email, setEmail] = useState<string>(initialEmail)
  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<string>('')
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)
  const [countdown, setCountdown] = useState<number>(2) // Timer for 2 seconds

  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<FormValues>({
    defaultValues: {
      email: initialEmail,
    },
  })

  useEffect(() => {
    setValue('email', initialEmail)
  }, [initialEmail, setValue])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (success) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            clearInterval(timer)
            navigate('/login')
          }
          return prevCountdown - 1
        })
      }, 1000)
    }
    return () => clearInterval(timer)
  }, [success, navigate])

  const handleVerification: SubmitHandler<FormValues> = async (data) => {
    const verificationCode = data.verificationCode.join('')
    try {
      await verifyOneTimeToken(data.email, verificationCode)
      setSuccess('Cuenta Creada! Redirigiendo al Login...')
      setError('')
      setIsButtonDisabled(true)
    } catch (err: any) {
      console.log('Error:', err.response.data.message)
      if (err.response.data.message.includes('Current status is CONFIRMED')) {
        setError('Su usuario ya fue verificado')
      } else {
        setError(err?.toString() || 'An error occurred')
      }
      setSuccess('')
    }
  }

  return (
    <Stack minH={'100vh'} direction={{base: 'column', md: 'row'}}>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <Stack
          spacing={4}
          w={'full'}
          maxW={'sm'}
          bg={useColorModeValue('white', 'gray.700')}
          rounded={'xl'}
          boxShadow={'lg'}
          p={6}
          my={10}
        >
          <Center>
            <Heading lineHeight={1.1} fontSize={{base: '2xl', md: '3xl'}}>
              Verifiquemos tu cuenta
            </Heading>
          </Center>
          <Center
            fontSize={{base: 'sm', sm: 'md'}}
            color={useColorModeValue('gray.800', 'gray.400')}
          >
            Te hemos enviado un codigo a tu correo
          </Center>
          <form onSubmit={handleSubmit(handleVerification)}>
            <FormControl
              isInvalid={!!errors.email}
              pb={2}
              onChange={(e: any) => setEmail(e.target.value)}
            >
              <Input
                placeholder='Ingresa tu correo electronico'
                value={email}
                mb={4}
                {...register('email', {
                  required: 'El correo electronico es requerido',
                  pattern: {
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                    message: 'Correo electronico invalido',
                  },
                })}
              />
              {errors.email && (
                <Alert status='error' mt={2}>
                  <AlertIcon />
                  {errors.email.message}
                </Alert>
              )}
            </FormControl>
            <FormControl isInvalid={!!errors.verificationCode}>
              <Center>
                <HStack>
                  <PinInput
                    onChange={(value) => setValue('verificationCode', value.split(''))}
                    value={errors.verificationCode ? '' : undefined}
                  >
                    {[...Array(6)].map((_, index) => (
                      <PinInputField
                        key={index}
                        {...register(`verificationCode.${index}`, {
                          required: 'El codigo de verificacion es requerido',
                          minLength: {value: 1, message: 'Cada campo debe tener 1 digito'},
                          maxLength: {value: 1, message: 'Cada campo debe tener 1 digito'},
                        })}
                      />
                    ))}
                  </PinInput>
                </HStack>
              </Center>
              {errors.verificationCode && (
                <Alert status='error' mt={2}>
                  <AlertIcon />
                  {errors.verificationCode.message}
                </Alert>
              )}
            </FormControl>
            {error && (
              <Alert status='error' mt={4}>
                <AlertIcon />
                {error}
              </Alert>
            )}
            {success && (
              <Alert status='success' mt={4}>
                <AlertIcon />
                {success} {countdown > 0 && `Redirigiendo en ${countdown}...`}
              </Alert>
            )}
            <Stack spacing={6} pt={5}>
              <Button
                type='submit'
                bg={'brand.300'}
                color={'white'}
                _hover={{
                  bg: 'brand.400',
                }}
                disabled={isButtonDisabled}
              >
                Validar
              </Button>
              <Button
                onClick={() => navigate('/')}
                bg={'brand.300'}
                color={'white'}
                _hover={{
                  bg: 'brand.400',
                }}
                disabled={isButtonDisabled}
              >
                Ir a Login
              </Button>
            </Stack>
          </form>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image alt={'Login Image'} objectFit={'cover'} src={'./assets/login_nature.webp'} />
      </Flex>
    </Stack>
  )
}

export default Verify
