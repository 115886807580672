import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import React, {ReactElement} from 'react'
import {
  FcCancel,
  FcDisclaimer,
  FcDonate,
  FcEndCall,
  FcNeutralDecision,
  FcUpload,
} from 'react-icons/fc'
import {Link} from 'react-router-dom'

interface CardProps {
  heading: string
  description: string
  icon: ReactElement
  href?: string
}

const Card = ({heading, description, icon, href}: CardProps) => {
  return (
    <Box
      maxW={{base: 'full', md: '275px'}}
      w={'full'}
      backgroundColor={useColorModeValue('white', 'gray.800')}
      borderWidth='1px'
      borderRadius='lg'
      overflow='hidden'
      p={5}
    >
      <Stack align={'start'} spacing={2}>
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          color={'white'}
          rounded={'full'}
          bg={useColorModeValue('gray.100', 'gray.700')}
        >
          {icon}
        </Flex>
        <Box mt={2}>
          <Heading size='md'>{heading}</Heading>
          <Text mt={1} fontSize={'sm'}>
            {description}
          </Text>
        </Box>
        {href ? (
          <Link to={`/${href}`}>
            <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
              ver más
            </Button>
          </Link>
        ) : (
          <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
            Proximamente
          </Button>
        )}
      </Stack>
    </Box>
  )
}

const HomePage = () => {
  return (
    <Box p={4}>
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading fontSize={{base: '2xl', sm: '4xl'}} fontWeight={'bold'}>
          Arcovia
        </Heading>
        <Text color={'gray.600'} fontSize={{base: 'sm', sm: 'lg'}}>
          Configura arcovia entrando a cualquiera de estos links
        </Text>
      </Stack>

      {/* <Container maxW={'5xl'} mt={12}>
        <Flex flexWrap='wrap' gridGap={6} justify='center'>
          <Card
            heading={'Consultar tus Datos'}
            icon={<Icon as={FcNeutralDecision} w={10} h={10} />}
            description={
              'Puedes solicitar acceso a tus datos personales para saber qué información tienen las empresas afiliadas sobre ti y cómo la usan.'
            }
            href={'request-data'}
          />
          <Card
            heading={'Actualizar tus Datos'}
            icon={<Icon as={FcUpload} w={10} h={10} />}
            description={
              'Si encuentras que tus datos están desactualizados o incorrectos, puedes pedirnos que los corrijamos en las bases de datos de las empresas afiliadas.'
            }
          />
          <Card
            heading={'Eliminar tus Datos'}
            icon={<Icon as={FcCancel} w={10} h={10} />}
            description={
              'Si ya no deseas que una empresa tenga tus datos para fines comerciales, puedes solicitar la eliminación de tu información de sus registros.'
            }
          />
          <Card
            heading={'Oponerte al Uso Comercial'}
            icon={<Icon as={FcDisclaimer} w={10} h={10} />}
            description={
              'Puedes oponerte a que cualquier empresa use tus datos personales para enviarte publicidad o promociones. Solo necesitas decirnos y respetaremos tu decisión.'
            }
          />

          <Card
            heading={'Solicitar Indemnización'}
            icon={<Icon as={FcDonate} w={10} h={10} />}
            description={
              'Si sientes que tus datos han sido utilizados de manera incorrecta por alguna empresa y te ha causado algún perjuicio, puedes reclamar una compensación por los daños.'
            }
          />
        </Flex>
      </Container> */}
    </Box>
  )
}

export default HomePage
