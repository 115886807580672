import {ChevronDownIcon} from '@chakra-ui/icons'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import React, {useCallback, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import CreateModal, {FieldType} from 'shared/components/CreateModal/CreateModal'
import {DeleteModal} from 'shared/components/DeleteModal/DeleteModal'
import DynamicTable from 'shared/components/DynamicTable/DynamicTable'
import {Column} from 'shared/components/DynamicTable/models'
import {Author, Blog} from 'shared/models/NestModels'
import {BlogType} from 'shared/models/NestTypes'
import {
  createAuthor,
  createBlog,
  getAllAuthors_paginated,
  getBlogsByType_paginated,
} from 'shared/requests/app-requests'

const fields = [
  {
    fieldName: 'file',
    friendlyUserName: 'Imágen',
    isRequired: true,
    type: FieldType.File,
  },
  {
    fieldName: 'type',
    friendlyUserName: 'Tipo',
    isRequired: true,
    type: FieldType.Picklist,
    options: ['Articulo', 'Podcast'],
  },
  {fieldName: 'name', friendlyUserName: 'Título', isRequired: true, type: FieldType.Text},
  {fieldName: 'slug', friendlyUserName: 'Url', isRequired: true, type: FieldType.Text},
  {
    fieldName: 'author',
    friendlyUserName: 'Autor',
    isRequired: true,
    type: FieldType.Lookup,
    fetchLookupData: (params: any) => getAllAuthors_paginated({...params}),
  },
  {fieldName: 'excerpt', friendlyUserName: 'Resumen', isRequired: false, type: FieldType.RichText},
  {
    fieldName: 'content',
    friendlyUserName: 'Detalle',
    isRequired: false,
    type: FieldType.RichText,
  },
]

const ArticlesPage = () => {
  const {isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose} = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [reload, setReload] = useState(0)
  const [selectedId, setSelectedId] = useState<string | null>(null)
  const toast = useToast()
  const navigate = useNavigate()

  const handleCreate = useCallback(
    (data: {[key: string]: any}) => {
      setIsLoading(true)
      createBlog(data as Blog)
        .then((res) => {
          toast({
            title: 'Autor creado con éxito',
            status: 'success',
            isClosable: true,
          })
          setTimeout(() => setReload((prev) => prev + 1), 1000)
        })
        .catch((err) => {
          console.error(err)
          toast({
            title: 'Error al crear el autor',
            status: 'error',
            isClosable: true,
          })
        })
        .finally(() => setIsLoading(false))
    },
    [toast]
  )

  // const handleDelete = useCallback(() => {
  //   if (selectedId) {
  //     deleteAccount(selectedId).then(() => {
  //       toast({
  //         title: 'Proveedor eliminado con éxito',
  //         status: 'success',
  //         isClosable: true,
  //       })
  //       setTimeout(() => setReload((prev) => prev + 1), 700)
  //       onAlertClose()
  //     })
  //   }
  // }, [selectedId, toast, onAlertClose])

  const handleDelete = () => {
    console.log('handleDelete')
  }

  const columns: Column<Blog>[] = [
    {
      header: 'Nombre',
      accessor: 'name',
      sortable: true,
    },
    {
      header: 'Tipo',
      accessor: 'type',
      sortable: true,
    },
    {
      header: 'Url',
      accessor: 'slug',
      sortable: true,
    },
    {
      header: 'Acción',
      sortable: false,
      component: (row: Blog) => (
        <Menu>
          <MenuButton as={IconButton} icon={<ChevronDownIcon />} variant='outline' />
          <MenuList zIndex={10}>
            <MenuItem onClick={() => navigate(`/article/view/${row.id}`)}>Ver</MenuItem>
            <MenuItem onClick={() => navigate(`/article/edit/${row.id}`)}>Editar</MenuItem>
            <MenuItem
              onClick={() => {
                setSelectedId(row.id)
                onAlertOpen()
              }}
            >
              Eliminar
            </MenuItem>
          </MenuList>
        </Menu>
      ),
    },
  ]

  return (
    <Card>
      <CardHeader>
        <Heading fontSize={'xx-large'} fontWeight={700}>
          Artículos
        </Heading>
        <Text>
          Configura en esta vista los articulos de Arcovia. Solo los publicados por un autor.
        </Text>
      </CardHeader>
      <CardBody>
        <Box position='absolute' right={0} m={6} zIndex={2}>
          <CreateModal fields={fields} onSubmit={handleCreate} objectFriendlyName='Artículo' />
        </Box>
        <DynamicTable
          columns={columns}
          fetchData={getBlogsByType_paginated}
          additionalParams={{type: ''}}
          setReload={setReload}
          reload={reload}
        />
      </CardBody>
      <DeleteModal
        handleDelete={handleDelete}
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
        title='Eliminar Proveedor'
        message='¿Está seguro de que desea eliminar este proveedor? Esta acción no se puede deshacer.'
      />
    </Card>
  )
}

export default ArticlesPage
