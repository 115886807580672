import React, {useState} from 'react'
import {
  AbsoluteCenter,
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  CloseButton,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react'
import {Image} from '@chakra-ui/image'
import {BsApple, BsGoogle} from 'react-icons/bs'
import {signIn, type SignInInput} from 'aws-amplify/auth'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'

type LoginFormInputs = {
  username: string
  password: string
}

const LoginContainer = () => {
  const [signInError, setSignInError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false) // Loading state
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: {errors},
    setError,
  } = useForm<LoginFormInputs>()

  async function onSubmit({username, password}: SignInInput) {
    setIsLoading(true)
    try {
      const {isSignedIn, nextStep} = await signIn({username, password})
      if (nextStep.signInStep === 'DONE') {
        navigate('/home')
        navigate(0)
      }
      // Handle post-sign-in actions here (e.g., redirect to dashboard)
    } catch (error: any) {
      console.error('SignIn error:', error)
      setSignInError(error.message || 'An error occurred during sign in.')

      setError('username', {
        type: 'manual',
        message: 'Failed to sign in. Please check your credentials.',
      })
      // Handle sign-in errors (e.g., show an error message)
    }
    setIsLoading(false)
  }
  // const {saveAuth, setCurrentUser} = useAuth()

  // const submitHandler = async (values: any, actions: any) => {
  //   try {
  //     const {data: auth} = await login(values.email, values.password)
  //     saveAuth(auth)
  //     const {data: user} = await getUserByToken(auth.api_token)
  //     setCurrentUser(user)
  //   } catch (error) {
  //     console.error(error)
  //     saveAuth(undefined)
  //     actions.setStatus({message: 'The login details are incorrect'})
  //     actions.setSubmitting({message: 'The login details are incorrect'})
  //   }
  // }

  return (
    <Stack minH={'100vh'} direction={{base: 'column', md: 'row'}}>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <Stack spacing={4} w={'full'} maxW={'md'}>
          <Heading fontSize='2xl' fontWeight={600} textAlign='center'>
            Iniciar Sesión
          </Heading>
          {/* <Text color='gray.600' fontSize='ml' textAlign='center'>
            ConPermiso
          </Text> */}
          <SimpleGrid columns={[1, 1, 1, 2]} gap={6} mt={6}>
            <Button
              leftIcon={<BsGoogle />}
              color={'white'}
              bg={'brand.300'}
              _hover={{bg: 'brand.400'}}
              _focus={{bg: 'brand.500'}}
            >
              Inicia sesion con google
            </Button>
            <Button
              leftIcon={<BsApple />}
              color={'white'}
              bg={'brand.300'}
              _hover={{bg: 'brand.400'}}
              _focus={{bg: 'brand.500'}}
            >
              Inicia sesion con Apple
            </Button>
          </SimpleGrid>
          <Box position='relative' padding='10'>
            <Divider />
            <AbsoluteCenter bg='white' px='4' fontSize='sm' color='gray.600'>
              o con tu correo
            </AbsoluteCenter>
          </Box>
          {signInError && (
            <Alert status='error' mb={4}>
              <AlertIcon />
              <AlertDescription>{signInError}</AlertDescription>
              <CloseButton
                position='absolute'
                right='8px'
                top='8px'
                onClick={() => setSignInError(null)}
              />
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={!!errors.username}>
              <FormLabel htmlFor='username'>Usuario</FormLabel>
              <Input id='username' {...register('username', {required: 'Usuario es requerido'})} />
              <FormErrorMessage>{errors.username && errors.username.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.password} mt={4}>
              <FormLabel htmlFor='password'>Contraseña</FormLabel>
              <Input
                id='password'
                type='password'
                {...register('password', {
                  required: 'Contraseña es requerida',
                  minLength: {
                    value: 8,
                    message: 'La contraseña debe tener al menos 8 caracteres de longitud',
                  },
                })}
              />
              <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
            </FormControl>

            <Button
              isLoading={isLoading}
              w='100%'
              mt={4}
              type='submit'
              color={'white'}
              bg={'brand.300'}
              _hover={{bg: 'brand.400'}}
              _focus={{bg: 'brand.500'}}
            >
              Iniciar sesión
            </Button>
            <Button
              w='100%'
              mt={4}
              color={'white'}
              bg={'brand.300'}
              onClick={() => navigate('/register')}
              _hover={{bg: 'brand.400'}}
              _focus={{bg: 'brand.500'}}
            >
              Registrarse
            </Button>
          </form>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image alt={'Login Image'} objectFit={'cover'} src={'./assets/login_nature.webp'} />
      </Flex>
    </Stack>
    // )
    // <SimpleGrid columns={[1, 1, 1, 2]} height="100vh">
    //   <Login />
    //   <Image className="banner_img" src="https://picsum.photos/700/700" h="100%" />
    // </SimpleGrid>
  )
}

export default LoginContainer
