import React, {useEffect, useState} from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Box,
  IconButton,
  InputGroup,
  InputLeftElement,
  Input,
  Skeleton,
} from '@chakra-ui/react'
import {FcNext, FcPrevious} from 'react-icons/fc'
import {Search2Icon} from '@chakra-ui/icons'
import {DynamicTableProps, Paginated} from './models'

type Cache<T> = {
  [key: string]: Paginated<T>
}

const DynamicTable = <T,>({
  columns,
  fetchData,
  setReload,
  additionalParams = {},
  reload = 0,
  additionalProps = {},
}: DynamicTableProps<T> & {additionalProps?: Record<string, any>}) => {
  const [data, setData] = useState<Paginated<T> | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [debouncedSearch, setDebouncedSearch] = useState<string>(search)
  const [limit, setLimit] = useState<number>(10)
  const [page, setPage] = useState<number>(1)
  const [sortColumn, setSortColumn] = useState<string | undefined>()
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const [cache, setCache] = useState<Cache<T>>({})

  useEffect(() => {
    setCache({})
  }, [reload])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search)
    }, 800)

    return () => {
      clearTimeout(handler)
    }
  }, [search])

  useEffect(() => {
    const fetchDataWithCache = async () => {
      const cacheKey = `${debouncedSearch || ''}-${page}-${limit}-${sortColumn || ''}-${sortOrder}`
      if (cache[cacheKey]) {
        setData(cache[cacheKey])
      } else if (fetchData) {
        setLoading(true)
        try {
          const result = await fetchData({
            search: debouncedSearch || '',
            page,
            limit,
            sortColumn,
            sortOrder,
            ...additionalParams,
          })
          setCache((prevCache) => ({
            ...prevCache,
            [cacheKey]: result,
          }))
          setData(result)
        } catch (error) {
          console.error('Error fetching data:', error)
        } finally {
          setLoading(false)
        }
      }
    }

    fetchDataWithCache()
  }, [debouncedSearch, page, limit, sortColumn, sortOrder, reload])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
    setPage(1) // Reset to page 1 on search change
  }

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
    setReload(reload + 1) // Trigger reload on page change
  }

  const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10))
    setPage(1)
    setReload(reload + 1) // Trigger reload on limit change
  }

  const handleSort = (column: string | undefined) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortColumn(column)
      setSortOrder('asc')
    }
    setPage(1)
    setReload(reload + 1) // Trigger reload on sort change
  }

  return (
    <Box>
      <InputGroup mb={4}>
        <InputLeftElement pointerEvents='none'>
          <Search2Icon color='gray.300' />
        </InputLeftElement>
        <Input
          placeholder='Buscar por nombre...'
          value={search}
          onChange={handleSearchChange}
          maxW='300px'
        />
      </InputGroup>
      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              {columns.map((column, index) => (
                <Th
                  isNumeric={column.isNumeric}
                  key={index}
                  onClick={() => column.sortable && handleSort(column.accessor?.toString())}
                  style={{
                    cursor: column.sortable ? 'pointer' : 'default',
                    width: column.width || 'auto',
                  }}
                >
                  {column.header}
                  {sortColumn === column.accessor && (sortOrder === 'asc' ? ' ↑' : ' ↓')}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {loading
              ? Array.from({length: limit}).map((_, rowIndex) => (
                  <Tr key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <Td key={colIndex} style={{width: column.width || 'auto'}}>
                        <Skeleton height='20px' />
                      </Td>
                    ))}
                  </Tr>
                ))
              : data?.data.map((row, rowIndex) => {
                  const idKey = columns.find((col) => col.isId)?.accessor || rowIndex
                  return (
                    <Tr key={(row[idKey as keyof T] as React.Key) || rowIndex}>
                      {columns.map((column, colIndex) => (
                        <Td
                          isNumeric={column.isNumeric}
                          key={colIndex}
                          style={{width: column.width || 'auto'}}
                        >
                          {column.component
                            ? column.component(row, additionalProps)
                            : column.accessor !== undefined
                            ? (row[column.accessor] as React.ReactNode)
                            : null}
                        </Td>
                      ))}
                    </Tr>
                  )
                })}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex mt={4} justifyContent='center'>
        <IconButton
          isRound={true}
          onClick={() => page > 1 && handlePageChange(page - 1)}
          color='brand.300'
          aria-label='Previous'
          icon={<FcPrevious />}
          mr={2}
        />
        <Box>
          {page} / {data?.totalPages ?? 1}
        </Box>
        <IconButton
          isRound={true}
          onClick={() => page < (data?.totalPages ?? 1) && handlePageChange(page + 1)}
          color='brand.300'
          aria-label='Next'
          icon={<FcNext />}
          ml={2}
        />
      </Flex>
    </Box>
  )
}

export default DynamicTable
