export enum BlogType {
  ARTICLE = "Articulo",
  PODCAST = "Podcast",
}

export enum OrderType {
  ASC = "asc",
  DESC = "desc",
}

export enum EpisodeIconType {
  APPLE = "ApplePodcast",
  YOTUBE = "Youtube",
  SPOTIFY = "Spotify",
}
