import {Box, Button, Card, Flex, Text} from '@chakra-ui/react'
import React from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import ArticleView from './Views/ArticleView'
import ArticleEdit from './Edits/ArticleEdit'
import AdvertisingEdit from './Edits/AdvertisingEdit'
import AdvertisingView from './Views/AdvertisingView'
import EpisodeEdit from './Edits/EpisodeEdit'
import EpisodeView from './Views/EpisodeView'

const RecordPage = () => {
  const {object, action, id} = useParams()
  const navigate = useNavigate()

  if (!object || !action || !id) {
    return <Text>Page not found</Text>
  }

  const toggleAction = () => {
    const newAction = action === 'view' ? 'edit' : 'view'
    navigate(`/${object}/${newAction}/${id}`)
  }

  const viewSiteSelector = () => {
    switch (object) {
      case 'article':
        return <ArticleView id={id} />
      case 'advertising':
        return <AdvertisingView id={id} />
      case 'episode':
        return <EpisodeView />
      default:
        return <Text>Page not found</Text>
    }
  }

  const editSiteSelector = () => {
    switch (object) {
      case 'article':
        return <ArticleEdit id={id} />
      case 'advertising':
        return <AdvertisingEdit id={id} />
      case 'episode':
        return <EpisodeEdit id={id} />
      default:
        return <Text>Page not found</Text>
    }
  }

  const siteSelector = () => {
    switch (action) {
      case 'view':
        return viewSiteSelector()
      case 'edit':
        return editSiteSelector()
      default:
        return <Text>Page not found</Text>
    }
  }

  return (
    <Card>
      <Box p={4} position='relative'>
        <Flex right='10' position='absolute'>
          <Button backgroundColor='brand.300' onClick={toggleAction} colorScheme='brand'>
            {action === 'view' ? 'Editar' : 'Cerrar'}
          </Button>
        </Flex>
      </Box>
      {siteSelector()}
    </Card>
  )
}

export default RecordPage
