import React, {useEffect, useState} from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  Image,
  SimpleGrid,
  Skeleton,
  Select,
  useToast,
} from '@chakra-ui/react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Import styles
import {getArticle, updateBlog} from 'shared/requests/app-requests'
import {Blog} from 'shared/models/NestModels'
import {BlogType} from 'shared/models/NestTypes'
import {useNavigate} from 'react-router-dom'

interface ArticleEditProps {
  id: string
}

const ArticleEdit = ({id}: ArticleEditProps) => {
  const [article, setArticle] = useState<Blog | null>(null)
  const [loading, setLoading] = useState(true)
  const [name, setName] = useState('')
  const [excerpt, setExcerpt] = useState('')
  const [content, setContent] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const toast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    getArticle(id).then((response) => {
      setArticle(response.data)
      setName(response.data.name || '')
      setExcerpt(response.data.excerpt || '')
      setContent(response.data.content || '')
      setLoading(false)
    })
  }, [id])

  const handleSave = () => {
    const formData = new FormData()

    formData.append('name', name)
    formData.append('excerpt', excerpt)
    formData.append('content', content)
    formData.append('type', article?.type || '')

    if (file) {
      formData.append('file', file)
    }

    formData.append('slug', article?.slug || '')

    updateBlog(formData, id).then((response) => {
      toast({
        title: 'Artículo actualizado.',
        description: 'El artículo ha sido actualizado con éxito.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      navigate(`/article/view/${id}`)
    })
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null
    setFile(selectedFile)
  }

  if (loading) {
    return (
      <Box padding='6' boxShadow='lg' bg='white'>
        <Skeleton height='40px' />
        <Skeleton height='200px' mt='4' />
        <Skeleton height='20px' mt='4' />
        <Skeleton height='20px' mt='4' />
        <Skeleton height='20px' mt='4' />
      </Box>
    )
  }

  return (
    <Box padding='6' boxShadow='lg' bg='white'>
      <form onSubmit={(e) => e.preventDefault()}>
        <input type='hidden' value={id} />

        <VStack spacing={4} align='start'>
          <FormControl id='headerImage'>
            <FormLabel>Imagen Principal:</FormLabel>
            {article?.headerImg && (
              <Image
                src={article.headerImg}
                alt={article.name}
                borderRadius='md'
                objectFit='cover'
                mb={4}
              />
            )}
            <Input type='file' onChange={handleFileChange} />
          </FormControl>
          <FormControl id='name'>
            <FormLabel>Título</FormLabel>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </FormControl>

          <SimpleGrid columns={[1, 2]} spacing={4}>
            <FormControl id='createdDate'>
              <FormLabel>Fecha de Creación</FormLabel>
              <Input
                value={
                  article?.createdDate
                    ? new Date(article.createdDate).toLocaleDateString()
                    : 'Unknown Date'
                }
                isReadOnly
              />
            </FormControl>

            <FormControl id='lastModifiedDate'>
              <FormLabel>Fecha Última Modificación</FormLabel>
              <Input
                value={
                  article?.lastModifiedDate
                    ? new Date(article.lastModifiedDate).toLocaleDateString()
                    : 'Unknown Date'
                }
                isReadOnly
              />
            </FormControl>

            <FormControl id='type'>
              <FormLabel>Típo de Blog</FormLabel>
              <Select
                value={article?.type || BlogType.ARTICLE}
                onChange={(e) =>
                  setArticle((prevArticle) => ({
                    ...prevArticle!,
                    type: e.target.value as BlogType,
                    slug: prevArticle?.slug ?? '',
                    excerpt: prevArticle?.excerpt ?? '',
                    headerImg: prevArticle?.headerImg ?? '',
                    content: prevArticle?.content ?? '',
                    episodes: prevArticle?.episodes ?? [],
                    createdBy: prevArticle?.createdBy ?? '',
                    lastModifiedBy: prevArticle?.lastModifiedBy ?? '',
                    createdDate: prevArticle?.createdDate ?? '',
                    lastModifiedDate: prevArticle?.lastModifiedDate ?? '',
                  }))
                }
              >
                <option value={BlogType.ARTICLE}>{BlogType.ARTICLE}</option>
                <option value={BlogType.PODCAST}>{BlogType.PODCAST}</option>
              </Select>
            </FormControl>

            <FormControl id='slug'>
              <FormLabel>URL</FormLabel>
              <Input value={article?.slug || ''} isReadOnly />
            </FormControl>
          </SimpleGrid>

          <FormControl id='excerpt'>
            <FormLabel>Resumen</FormLabel>
            <ReactQuill value={excerpt} onChange={setExcerpt} />
          </FormControl>

          <FormControl id='content'>
            <FormLabel>Blog</FormLabel>
            <ReactQuill value={content} onChange={setContent} />
          </FormControl>

          <Button colorScheme='brand' backgroundColor='brand.300' onClick={handleSave}>
            Guardar
          </Button>
        </VStack>
      </form>
    </Box>
  )
}

export default ArticleEdit
