import {combineReducers, configureStore} from '@reduxjs/toolkit'
import appReducer from './reducer'
import {loadState, saveState} from './sessionStorage'

const preloadedState = loadState()

const reducer: any = {
  appState: appReducer,
}

export const store = configureStore({
  reducer,
  preloadedState,
})

store.subscribe(() => {
  saveState(store.getState())
})

export type RootState = ReturnType<typeof store.getState>
